import React from 'react';
import cx from 'classnames';
import s from './ProductPrice.scss';
import {ProvidedGlobalProps, withGlobalProps} from '../../../providers/globalPropsProvider';
import {LayoutContext} from '../../ProductPageApp/ProductPageApp';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ISubscriptionPlan} from '../../ProductOptions/SubscriptionPlans/SubscriptionPlan/SubscriptionPlan';
import {MeasurementUnitType} from '../../../graphql/queries-schema';
import {unitsTranslations} from './units-translations';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/add-to-cart-service/constants';

export interface ProductPriceProps extends ProvidedGlobalProps {
  actualPrice: number;
  passivePrice?: number;
  formattedActualPrice: string;
  formattedPassivePrice?: string;
  className?: string;
  plan?: ISubscriptionPlan;
  pricePerUnit?: number;
  formattedPricePerUnit?: string;
  baseMeasurementUnit?: MeasurementUnitType;
  baseQuantity?: number;
}

@withGlobalProps
@withTranslations('globals.texts')
export class ProductPrice extends React.Component<ProductPriceProps & IProvidedTranslationProps> {
  private renderPrimary() {
    const {formattedActualPrice, passivePrice, t, plan} = this.props;

    const shouldShowSubscriptionPlanFrequency = plan && !plan.isOneTimePurchase;
    const primaryTranslationKey = passivePrice
      ? 'SR_PRODUCT_PRICE_AFTER_DISCOUNT'
      : 'SR_PRODUCT_PRICE_WHEN_THERE_IS_NO_DISCOUNT';

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-primary-price" className={s.primary}>
          {formattedActualPrice}
        </span>
        <span className="sr-only" data-hook="sr-formatted-primary-price">
          {t(primaryTranslationKey)}
        </span>
        {shouldShowSubscriptionPlanFrequency && (
          <span data-hook="subscription-plan-frequency" className={s.subscriptionPlanFrequency}>
            &nbsp;
            {t('PRODUCT_PAGE_SUBSCRIPTION_PRICE_DESCRIPTION', {
              frequencyUnitSingular: this.getFrequencyUnitSingularTranslation(),
            })}
          </span>
        )}
      </div>
    );
  }

  private renderSecondary() {
    const {formattedPassivePrice, t} = this.props;

    return (
      <div className={s.formattedPriceContainer}>
        <span data-hook="formatted-secondary-price" className={s.secondary}>
          {` ${formattedPassivePrice} `}
        </span>
        <span className="sr-only" data-hook="sr-formatted-secondary-price">
          {t('SR_PRODUCT_PRICE_BEFORE_DISCOUNT')}
        </span>
      </div>
    );
  }

  private getFrequencyUnitSingularTranslation(): string {
    const {t, plan} = this.props;

    switch (plan.frequency) {
      case 'DAY':
        return 'DAY_QA';
      case 'WEEK':
        return t('PRODUCT_PAGE_PLAN_WEEK');
      case 'MONTH':
        return t('PRODUCT_PAGE_PLAN_MONTH');
      case 'YEAR':
        return t('PRODUCT_PAGE_PLAN_YEAR');
    }
  }

  private getUnitTranslations(quantity: number, isAbbreviation: boolean = false): string {
    const {t, baseMeasurementUnit} = this.props;
    if (isAbbreviation) {
      return t(unitsTranslations[baseMeasurementUnit].abbr);
    } else {
      return quantity === 1
        ? t(unitsTranslations[baseMeasurementUnit].singular)
        : t(unitsTranslations[baseMeasurementUnit].plural);
    }
  }

  public render(): JSX.Element {
    const {
      actualPrice,
      formattedPassivePrice,
      formattedPricePerUnit,
      className,
      globals: {
        addToCartState,
        experiments: {isSellingInUnitsEnabled},
      },
    } = this.props;

    const hasZeroPrice = actualPrice === 0;
    const isAddToCartDisabled = addToCartState === AddToCartState.DISABLED;
    const shouldRenderUnitPrice = isSellingInUnitsEnabled && !!formattedPricePerUnit;

    if (isAddToCartDisabled && hasZeroPrice) {
      return null;
    }

    return (
      <LayoutContext.Consumer>
        {(layoutName) => (
          <div data-hook="product-prices-wrapper" className={className}>
            <div data-hook="product-price" className={cx(s.prices, s[`prices__${layoutName}`])}>
              {Boolean(formattedPassivePrice) && this.renderSecondary()}
              {this.renderPrimary()}
            </div>
            {shouldRenderUnitPrice && this.renderUnitPrice()}
          </div>
        )}
      </LayoutContext.Consumer>
    );
  }

  private renderUnitPrice() {
    const {t, formattedPricePerUnit, baseQuantity} = this.props;
    const unitTranslation = this.getUnitTranslations(baseQuantity);
    const srFormattedUnitAndQuantity = `${baseQuantity} ${unitTranslation}`;

    return (
      <div data-hook="product-unit-price" className={s.unitPrice}>
        <span
          aria-hidden="true"
          data-hook={'formatted-unit-price'}>{`${formattedPricePerUnit} / ${baseQuantity}`}</span>
        <span aria-hidden="true" data-hook={'unit-price-unit-type'}>
          {this.getUnitTranslations(0, true)}
        </span>
        <span className="sr-only" aria-hidden="false" data-hook="unit-price-sr">
          {t('SR_PRODUCT_PAGE_BASE_UNIT_PRICE', {basePrice: formattedPricePerUnit, units: srFormattedUnitAndQuantity})}
        </span>
      </div>
    );
  }
}
