export function queryToString(queryStringObj: Record<string, any>) {
  return Object.keys(queryStringObj)
    .map((prop) => `${prop}=${encodeURIComponent(queryStringObj[prop]).replace(/%20/g, '+').replace(/\*/g, '%2A')}`)
    .join('&');
}

export function stringToQuery(rawQuery: string): any {
  const query = {};
  rawQuery
    .replace(/^\?/, '')
    .split('&')
    .forEach((piece) => {
      const [key, value] = piece.split('=');
      query[key] = decodeURIComponent(value).replace('+', ' ');
    });
  return query;
}
